/*
 *  Geofabrik OSM Inspector
 *
 *  © 2008-2022 Geofabrik GmbH
 *
 */

'use strict';

var jquery = require("jquery");
window.jQuery = jquery; // notice the definition of global variables here
const fancytree = require('jquery.fancytree');
import LayerSwitcher from 'ol-layerswitcher';
import Control from 'ol/control/Control';
import * as olExtent from 'ol/extent';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import * as olProj from 'ol/proj';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import View from 'ol/View';

const MIN_EDIT_ZOOM = 15;

// fancytree
var tree = null;

/* default values for URL parameters */
var default_view      = 'geometry';
var default_baselayer = 'Geofabrik Standard';
var default_opacity   = 0.3;

var view      = default_view;
var baselayer = default_baselayer;
var opacity   = default_opacity;

var lon       = -12;
var lat       = 25;
var mlon      = null;
var mlat      = null;
var zoom      = 3;

var overlays     = new Array;
var overlays_all = new Array;
var marker = null;
var view_data;

var minzoom;

var single_overlay = null;

var expand_layers = Object;
var infoVisible = false;

var text;

var longtype = { r: 'relation', w: 'way', n: 'node' };
var layerNameKey = '_layer_name';

var projExtent = olProj.get('EPSG:3857').getExtent();
var startResolution = olExtent.getWidth(projExtent) / 256;
var resolutions = new Array(19);
for (var i = 0, ii = resolutions.length; i < ii; ++i) {
  resolutions[i] = startResolution / Math.pow(2, i);
}
var maxMerc = 20037508.342789244;

/* all available views */
var views = [
    { id: 'geometry',         area: 'world', name: 'Geometry' },
    { id: 'tagging',          area: 'world', name: 'Tagging' },
    { id: 'places',           area: 'world', name: 'Places' },
    { id: 'highways',         area: 'world', name: 'Highways' },
    { id: 'areas',     area: 'world', name: 'Areas' },
    { id: 'coastline',        area: 'world', name: 'Coastline' },
    { id: 'routing',          area: 'world', name: 'Routing' },
    { id: 'addresses',        area: 'world', name: 'Addresses' },
    { id: 'water',            area: 'world', name: 'Water' },
    { id: 'pubtrans_stops',   area: 'world', name: 'Public Transport - Stops' },
    { id: 'pubtrans_routes',  area: 'world', name: 'Public Transport - Routes' }
];
/* views using a data source in EPSG:3857 projection */
var webMercatorBasedViews = new Array('geometry', 'tagging', 'places', 'highways', 'pubtrans_routes', 'pubtrans_stops');

var current_view;
var map;
var baselayers = {};
var layer_wms;

var proj4326  = new olProj.Projection({code: 'EPSG:4326'});
var projmerc  = new olProj.Projection({code: 'EPSG:3857'});
var mercTo4326 = olProj.getTransform(projmerc, proj4326);

class EditorControl extends Control {
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.type = 'button';
        button.title = options.title;

        const element = document.createElement('div');
        element.id = options.id;
        element.classList.add('ol-unselectable');
        element.classList.add('ol-control');
        element.classList.add('olextra-editor-control');
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', options.clickCallback, false);
    }
}

class SmallSpaceControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.title = options.title;
        button.appendChild(document.createTextNode(options.label));

        const element = document.createElement('div');
        element.classList.add('olextra-small-space-control');
        element.classList.add('ol-unselectable');
        element.classList.add('ol-control');
        element.classList.add(options.className);
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', options.clickCallback, false);
    }
}

document.addEventListener("DOMContentLoaded", start);

function start() {
    parseQueryString();
    initViews(view);
    initOpacitySlider();
    initBaseLayers();
    initMap();

    document.addEventListener('keydown', key_pressed);
    initTree();
};

document.getElementById('close-icon').addEventListener('click', function(e) {showOrHideControlModal('', true);});
document.getElementById('close-icon-right').addEventListener('click', function(e) {showOrHideControlModal('', true, true);});
document.getElementById('close-icon-overlayinfo').addEventListener('click', function(e) {showOrHideInfo(e, false);});

function showTreeErrorMsg() {
    document.getElementById('message').textContent = 'Failed to download layer list for this view. Please select a different view.';
}

function initTree() {
    fetch(viewLink('/view.json'))
        .then(response => response.json())
        .then(data => {
            view_data = data;
            setupTree(data);
        })
        .catch((error) => {
            showTreeErrorMsg();
        });
}

function setupTree(data) {
    jquery('#overlays').fancytree({
        checkbox: true,
        selectMode: 3,
        imagePath: '/osmi/views/',
        select: function(ev, data) {
            single_overlay = null;
            overlays = new Array;
            data.tree.visit(
                function(dtnode/*, data*/) {
                    if (dtnode.isSelected() && dtnode.key.substring(0, 1) != '_') {
                        overlays.push(dtnode.key);
                    }
                    return true;
                }
            );
            updateWMSLayer();
            updateOverlayShading(data.tree);
            updateLinks();
        },
        click: function(ev, data) {
            if (data.targetType == 'expander') {
                return;
            }
            var node = data.node;
            showOrHideInfo(ev, data);
            if (single_overlay === data.node.key) {
                node.removeClass('focused-layer');
                data.tree.visit(function(nd) {
                    nd.setActive(true);
                    nd.setSelected(true);
                });
                single_overlay = null;
                updateWMSLayer();
            } else if (data.node.key.substring(0, 1) != '_' && data.targetType === 'title')  {
                data.tree.visit(function(nd) {
                    nd.removeClass('focused-layer');
                    nd.setActive(false);
                    nd.setSelected(false);
                });
                data.node.addClass('focused-layer');
                data.node.setActive(true);
                data.node.setSelected(true);
                single_overlay = data.node.key;
                updateWMSLayer();
            } else  {
                if (data.targetType != 'checkbox') {
                    data.node.toggleExpanded();
                }
                var oneLayerFocused = false;
                data.tree.visit(function(nd) {
                    oneLayerFocused = oneLayerFocused || nd.hasClass('focused-layer');
                    nd.removeClass('focused-layer');
                });
                single_overlay = null;
                if (oneLayerFocused) {
                    updateWMSLayer();
                }
            }
        },
        source: {
            url: viewLink('/overlays.json')
        },
        init: function(ev, data){
            if (overlays.length == 0) {
                data.tree.selectAll(true);
                getOverlays(data.tree);
            } else {
                overlays.forEach(function(o) {
                    data.tree.visit(function(node) {
                        node.selected = true;
                        return true;
                    });
                });
            }
            initView2(data.tree);
        },
    });
}

function createNewOption(value, text, selected) {
    var option = document.createElement('option');
    option.textContent = text;
    option.value = value;
    option.selected = selected;
    return option;
}

function viewLink(postfix) {
    return '/osmi/views/' + current_view['id'] + postfix;
}


function getOverlays(tree_data) {
    tree_data.visit(
        function(dtnode/*, data*/){
            if (dtnode.key.substring(0,1) != '_') { // skip headings
                overlays.push(dtnode.key);
            }
            return true;
        }
    );
}


function updateTree() {
    var tree = jQuery.ui.fancytree.getTree('#overlays');
    if (tree == null) {
        initTree();
        return;
    }
    overlays = [];
    expand_layers = Object;
    tree.reload({
        url: viewLink('/overlays.json'),
    }).then(updateWMSLayer());
}

async function initView2(tree) {
    var newoverlays = new Array;
    minzoom = 30;
    // Iterating over the tree should not happen asynchronously because we would then first try to
    // load WMS images without any layer.
    await jQuery.ui.fancytree.getTree('#overlays').visit(
                function(node) {
                if (node.data.osmi_zmin < minzoom) {
                    minzoom = node.data.osmi_zmin;
                }
                if (node.key.substring(0, 1) != '_') {
                    expand_layers[node.key] = node.data.osmi_additional_layers ? node.data.osmi_additional_layers : [];
                    expand_layers[node.key].unshift(node.key);
                    newoverlays.push(node.key);
                }
                return true;
            },
                false
        );
    if (overlays.length == 0) {
        overlays = newoverlays;
    }
    fetch(viewLink('/overlayinfo.html'))
        .then(function(response) {
            if (!response.ok) {
                throw response;
            }
            return response.text();
        })
        .then(function(body) {
            document.getElementById('overlayinfos-content').innerHTML = body;
        });
    document.getElementById('doclink').href = 'https://wiki.openstreetmap.org/wiki/OSM_Inspector/Views/' + encodeURIComponent(current_view['name']);
    fetch(viewLink('/tstamp'))
        .then(function(response) {
            if (!response.ok) {
                throw response;
            }
            return response.text();
        })
        .then(function(body) {
            document.getElementById('tstamp').textContent = body;
            initView3(tree);
        })
        .catch(function() {
            document.getElementById('tstamp').textContent = 'unknown';
            initView3(tree);
        });
}

function buildWMSLayer(wms_layers) {
    if (!wms_layers) {
        return null;
    }
    var wms_source = new TileWMS({
        url: viewLink('/wxs?'),
        params: {'LAYERS': wms_layers, 'TILED': true, 'TRANSPARENT': 'TRUE'},
        serverType: 'mapserver',
        crossOrigin: 'anonymous',
    });
    return new TileLayer({source: wms_source});
}

function updateWMSLayer() {
    map.removeLayer(layer_wms);
    if (single_overlay != null) {
        layer_wms = buildWMSLayer(expand_layers[single_overlay]);
    } else {
        layer_wms = buildWMSLayer(getWMSLayers());
    }
    if (layer_wms) {
        map.addLayer(layer_wms);
    }
}

function getWFSQueryString(layerName, coordinate) {
    var featureInfoUrl = layer_wms.getSource().getFeatureInfoUrl(
        coordinate,
        map.getView().getResolution(),
        'EPSG:3857',
        {
            'INFO_FORMAT': 'geojson',
            'QUERY_LAYERS': layerName,
        }
    );
    return featureInfoUrl;
}

function getDistance(coord1, geom) {
    var closestPoint = geom.getClosestPoint(coord1);
    var deltaX = closestPoint[0] - coord1[0];
    var deltaY = closestPoint[1] - coord1[1];
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
}

function nestedLayersToFlat(items) {
    var results = [];
    for (var i = 0; i < items.length; ++i) {
        var item = items[i];
        if (item.hasOwnProperty('children')) {
            results.push(...nestedLayersToFlat(item.children));
        } else {
            results.push(item);
        }
    };
    return results;
}

function getDataForLayer(layerName) {
    var treeData = jQuery.ui.fancytree.getTree('#overlays').toDict();
    var layersFlat = nestedLayersToFlat(treeData);
    for (var i = 0; i < layersFlat.length; ++i) {
        if (layersFlat[i].key === layerName) {
            return layersFlat[i];
        }
    }
}

function getTranslationForLayer(layerName) {
    var t = getDataForLayer(layerName);
    t.getTranslation = function(key) {
        var data = this.data;
        if (data.hasOwnProperty("field_labels")) {
            if (data.field_labels.hasOwnProperty(key)) {
                return data.field_labels[key]["label"] || key;
            }
        }
        return key;
    };

    t.getTitle = function() {
        return this.title;
    };

    t.getIconURL = function() {
        return '/osmi/views/' + this.icon;
    };

    t.isKeyBold = function(key) {
        var data = this.data;
        if (data.hasOwnProperty("field_labels")) {
            if (data.field_labels.hasOwnProperty(key) && data.field_labels[key].hasOwnProperty("type")) {
                return data.field_labels[key]["type"] == "one_char_bool";
            }
        }
        return false;
    };

    t.renderValue = function(key, value) {
        var data = this.data;
        if (data.hasOwnProperty('field_labels')) {
            if (data.field_labels.hasOwnProperty(key) && data.field_labels[key].hasOwnProperty("type")) {
                if (data.field_labels[key]['type'] == 'one_char_bool' && value === 'T') {
                    return 'true';
                }
            }
        }
        return value;
    };
    return t;
}

function infoTemplateForFeature(feature, lastLayerName) {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/template
    var layerName = feature.get(layerNameKey);
    var translation = getTranslationForLayer(layerName);
    var template = document.getElementById('featureinfo-template');
    var clone = template.content.cloneNode(true).querySelector('.sel_result');
    var layerNameNode = clone.querySelector('div.feature-layer-name');
    var returnNodes = [];
    if (lastLayerName != layerName) {
        var layerNameNode = template.content.querySelector('div.feature-layer-name').cloneNode(true);
        layerNameNode.querySelector('span').textContent = translation.getTitle();
        layerNameNode.querySelector('img').src = translation.getIconURL();
        returnNodes.push(layerNameNode);
    }
    clone.querySelector('.button.zoom_to_object').addEventListener('click', function(e) {
        map.getView().fit(feature.getGeometry());
    });
    var osmTypeAndId = getOsmTypeAndId(feature.getProperties());
    var idLink = createIDLink(feature.getGeometry(), osmTypeAndId[0], osmTypeAndId[1])
    clone.querySelector('a.sel_link_to_id').setAttribute('href', idLink);
    var mapExtent = map.getView().calculateExtent(map.getSize());
    var extentForJOSM = feature.getGeometry().getExtent();
    if (map.getView().getZoom() >= 16 && olExtent.containsExtent(mapExtent, extentForJOSM)) {
        // On zoom level 16+, we use the map view if the feature is in the current view.
        extentForJOSM = mapExtent;
    }
    var josmLink = createJOSMLink(
        olProj.transformExtent(
            extentForJOSM,
            projmerc,
            proj4326
        ),
        osmTypeAndId[0], osmTypeAndId[1]);
    clone.querySelector('img.button.sel_link_to_josm').addEventListener('click', function(e) {
        fetch(josmLink).then(function(result) {
            if (!result.ok) {
                return Promise.reject(result);
            }
        }).catch(function(error) {
            console.warn(error);
        });
    });
    var osmOrgLink = createDataBrowserLink(osmTypeAndId[0] == 'rel' ? 'relation' : osmTypeAndId[0], osmTypeAndId[1])
    clone.querySelector('a.sel_link_to_databrowser').setAttribute('href', osmOrgLink);
    var deepHistoryLink = createDeepHistoryLink(osmTypeAndId[0] == 'rel' ? 'relation' : osmTypeAndId[0], osmTypeAndId[1])
    clone.querySelector('a.sel_link_to_deephistory').setAttribute('href', deepHistoryLink);
    var tbody = clone.querySelector('tbody');
    var tr = tbody.querySelector('tr');
    feature.getKeys().forEach(function(key) {
        var clonedRow = tr.cloneNode(true);
        var value = feature.get(key);
        if (key === layerNameKey || key === 'geometry' || value == null || value == '') {
            return;
        }
        var keyTranslated = translation.getTranslation(key);
        var valueToRender = translation.renderValue(key, value);
        var keyCell = clonedRow.querySelector('td.feature-key');
        keyCell.textContent = keyTranslated;
        if (translation.isKeyBold(key)) {
            keyCell.classList.add('feature-key-bold');
        }
        clonedRow.querySelector('td.feature-value').textContent = valueToRender;
        tbody.appendChild(clonedRow);
    });
    tr.parentNode.removeChild(tr);
    clone.classList.add('selection_detail');
    returnNodes.push(clone);
    return returnNodes;
}

function initView3(tree) {
    tree.visit(function(node) {
        node.setExpanded(true);
        return true;
    });

    updateWMSLayer();
    zoomInMessage();
    updateView(tree);

    if (mlon && mlat) {
        setMarker(mlon, mlat);
    }

}

async function getFeatureInfo(e) {
	showOrHideControlModal('box_selection', false, true);
        document.getElementById('selection-loading').classList.remove('hide');
        var layersToQuery = (single_overlay == null) ? getQueryableLayers() : expand_layers[single_overlay];
        var featureCollections = [];
        var geoJSONFeatures = {
            "type": "FeatureCollection",
            "name": "results",
            "crs": {
                "type": "name",
                "properties": {
                    "name": "urn:ogc:def:crs:EPSG::3857"
                }
            },
            "features": []
        }
        removeDOMNodesByClassName('feature-layer-name');
        removeDOMNodesByClassName('sel_result');
        await Promise.all(layersToQuery.map(layerName => fetch(getWFSQueryString(layerName, e.coordinate)))).then(
            responses => Promise.all(
                responses.map(
                    res => {
                        return res.ok ? res.json() : null;
                    }
            ))
        ).then(results => {
            results.forEach(function(r) {
                if (!r) {
                    return;
                }
                r['features'].forEach(function(feature) {
                    feature.properties[layerNameKey] = r['name'];
                    geoJSONFeatures['features'].push(feature);
                });
            });
        }).catch(error => { console.error(error)});
        if (geoJSONFeatures.features.length == 0) {
            showOrHideControlModal('', true, true);
        } else {
            var features = new GeoJSON().readFeatures(geoJSONFeatures, {dataProjection: 'EPSG:3857'});
            features.sort(function(lhs, rhs) {
                if (lhs.get(layerNameKey) != rhs.get(layerNameKey) && lhs.get(layerNameKey) < rhs.get(layerNameKey)) {
                    return -1;
                } else if (lhs.get(layerNameKey) != rhs.get(layerNameKey) && lhs.get(layerNameKey) > rhs.get(layerNameKey)) {
                    return 1;
                }
                return getDistance(e.coordinate, lhs.getGeometry()) - getDistance(e.coordinate, rhs.getGeometry());
            });
            var lastLayerName = null;
            for (var i = 0; i < features.length; ++i) {
                var infos = infoTemplateForFeature(features[i], lastLayerName);
                infos.forEach(function(e) {
                    document.querySelector('#selection').appendChild(e);
                });
                lastLayerName = features[i].get(layerNameKey);
            }
        }
        document.getElementById('selection-loading').classList.add('hide');
        showSelectionBox(true);
        e.stopPropagation();
}

/**
 * Show selection box or hide it.
 */
function showSelectionBox(show) {
    if (show) {
        document.getElementById('sidebar-right').classList.remove('hide-1050');
        document.getElementById('sidebar-right').classList.remove('hide-760');
    } else {
        document.getElementById('sidebar-right').classList.add('hide-1050');
        document.getElementById('sidebar-right').classList.add('hide-760');
    }
}

/* parse URL query string and set global variables accordingly */
function parseQueryString() {
    var perma = location.search.substr(1);
    if (perma != '') {
        var paras = perma.split('&');
        for (var i = 0; i < paras.length; i++) {
            var p = paras[i].split('=');
            p = [decodeURIComponent(p[0]), decodeURIComponent(p[1])];
            switch (p[0]) {
                case 'view':      view      = p[1]; break;
                case 'baselayer': baselayer = p[1]; break;
                case 'opacity':   opacity   = Number(p[1]); break;
                case 'lon':       lon       = Number(p[1]); break;
                case 'lat':       lat       = Number(p[1]); break;
                case 'overlays':
                    if (p[1] != '') {
                        overlays  = p[1].split(',');
                    }
                    break;
                case 'zoom':      zoom      = parseInt(p[1]); break;
                case 'mlon':      mlon      = Number(p[1]); break;
                case 'mlat':      mlat      = Number(p[1]); break;
            }
        }
    }
}

function initViews(selected) {
    var select = document.getElementById('view-switcher');
    select.addEventListener('change', changedView);
    views.forEach(function(view) {
        var option = createNewOption(view['id'], view['name'], view['id'] == selected);
        option.style.backgroundImage = 'url(//static.geofabrik.de/img/flags/' + view['area'] + '.png)';
        var select = document.getElementById('view-switcher');
        select.appendChild(option);
        if (view['id'] == selected) {
            current_view = view;
        }
    });
}

function initMap() {
    var extraControls = [
        new EditorControl({
            title: 'Open current map view in JOSM',
            src: './img/to_josm.png',
            alt: 'simplified monchrome JOSM logo',
            id: 'open-in-josm',
            clickCallback: openJOSMforMap,
        }),
        new EditorControl({
            title: 'Open current map view in iD',
            src: './img/to_id.png',
            alt: 'image showing the characters "iD"',
            id: 'open-in-id',
            clickCallback: openIDforMap,
        }),
        new SmallSpaceControl({
            label: 'View',
            title: 'Select view',
            className: 'olextra-view-control',
            clickCallback: function() {
                showOrHideControlModal('view-switcher-form', false);
            } 
        }),
        new SmallSpaceControl({
            label: 'Transparency',
            title: 'Change transparency',
            className: 'olextra-base-control',
            clickCallback: function() {
                showOrHideControlModal('base-layer-box', false);
            }
        }),
        new SmallSpaceControl({
            label: 'Overlays',
            title: 'Select overlays',
            className: 'olextra-overlay-control',
            clickCallback: function() {
                showOrHideControlModal('box_overlays', false);
            } 
        }),
    ];
    for (const [name, layer] of Object.entries(baselayers)) {
        layer.setVisible(false);
    };
    baselayers[baselayer].setVisible(true);
    map = new Map({
        target: 'map',
        view: new View({
            center: olProj.fromLonLat([lon, lat]),
            zoom: zoom,
            maxZoom: 19,
            minZoom: 0,
            constrainResolution: true
        }),
        layers: Object.values(baselayers),
    });
    map.on('click', getFeatureInfo);
    var layerSwitcher = new LayerSwitcher({
        tipLabel: 'Legende', // Optional label for button
        groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
    });
    map.addControl(layerSwitcher);
    extraControls.forEach(function(e) {
        map.addControl(e);
    });
    map.on('moveend', function() {
        updateView(null);
        zoomInMessage();
        checkEditorButtons();
    });
}

function showOrHideControlModal(panelID, hide, right) {
    var sidebarIDs = ['sidebar-left', 'sidebar-right'];
    for (var j = 0; j < sidebarIDs.length; ++j) {
        var sidebar = document.getElementById(sidebarIDs[j]);
        sidebar.classList.remove('control-modal-enabled');
        for (var i = 0; i < sidebar.children.length; ++i) {
            sidebar.children[i].classList.remove('tool-enabled');
        }
    }
    document.getElementById('map').classList.remove('modal-active');
    if (!hide) {
        var sidebarID = 'sidebar-left';
        if (right || false) {
            sidebarID = 'sidebar-right';
        }
        document.getElementById(panelID).classList.add('tool-enabled');
        document.getElementById(sidebarID).classList.add('control-modal-enabled');
        document.getElementById('map').classList.add('modal-active');
        showOrHideInfo(null, false);
    }
}

function zoomInMessage() {
    if (map.getView().getZoom() < minzoom) {
        document.getElementById('message-box').style.display = 'block';
        document.getElementById('message').textContent = 'You have to zoom in to see anything in this view.';
    } else {
        document.getElementById('message-box').style.display = 'none';
    }
}

function getGeofabrikTileUrlTemplate(key) {
    return 'https://tile.geofabrik.de/' + key + '/{z}/{x}/{y}.png';
}

function buildTileLayer(url, name) {
    return new TileLayer({
        title: name,
        type: 'base',
        source: new OSM({'url': url})
    });
}

function initBaseLayers() {
    var key = "549e80f319af070f8ea8d0f149a149c2";
    baselayers['Geofabrik Standard'] = buildTileLayer(getGeofabrikTileUrlTemplate('549e80f319af070f8ea8d0f149a149c2'), 'Geofabrik Standard');
    baselayers['Geofabrik German'] = buildTileLayer(getGeofabrikTileUrlTemplate('23228979966ae9040ceb0597251e12a2'), 'Geofabrik German');
    baselayers['Geofabrik Topo'] = buildTileLayer(getGeofabrikTileUrlTemplate('15173cf79060ee4a66573954f6017ab0'), 'Geofabrik Topo');
    baselayers['OSM Carto (OSMF)'] = buildTileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', 'OSM Carto (OSMF)');
    baselayers['ÖPNVKarte'] = buildTileLayer('https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png', 'ÖPNVKarte');

    for (var layer in baselayers) {
        var layerobj = baselayers[layer];
        layerobj.setOpacity(opacity);
        layerobj.on('change:visible', updateLinks);
    }
}

function changeOpacity(ev) {
    opacity = Number.parseFloat(ev.target.value);
    for (var layer in baselayers) {
        baselayers[layer].setOpacity(opacity);
    }
}

function changeOpacityAndUpdateUrl(ev) {
    changeOpacity(ev);
    updateLinks();
}

function initOpacitySlider() {
    var slider = document.getElementById('opacity_slider');
    slider.addEventListener('input', changeOpacity);
    slider.addEventListener('change', changeOpacityAndUpdateUrl);
}

/* set map center to new location given in EPSG:4326 */
function jumpTo(lon, lat, zoom) {
    var pos = [lon, lat];
    var map_view = map.getView();
    map_view.setCenter(olProj.transform(pos, proj4326, projmerc));
    map_view.setZoom(zoom);
}

//function jumpToFeature(id, coordinates) {
//    var coords = [];
//
////TODO get rid of eval
//    jQuery.each(coordinates.split(' '), function(i, c) {
//        eval('coords.add(' + c + ')');
//    });
//    coords = coords.map(x => olProj.transform(x, proj4326, projmerc));
//    var bounds = olExtent.boundingExtend(coords);
//    map.getView.fit(bounds);
//}

function removeDOMNodesByClassName(name) {
    var layerNameHeadings = document.getElementsByClassName(name);
    var arr = Array.from(layerNameHeadings);
    arr.forEach(function(elem) {
        elem.parentNode.removeChild(elem);
    });
}

/* this is called whenever the view form has changed */
function changedView() {
    showOrHideControlModal('', true, true);
    removeDOMNodesByClassName('feature-layer-name');
    removeDOMNodesByClassName('sel_result');
    showSelectionBox(false);
    views.forEach(function(view) {
        var viewSwitcher = document.getElementById('view-switcher');
        if (view['id'] == viewSwitcher.value) {
            current_view = view;
        }
    });
    map.removeLayer(layer_wms);
    overlays = new Array;
    overlays_all = new Array;
    fetch(viewLink('/view.json'))
        .then(response => response.json())
        .then(function(data) {
            view_data = data;
            updateTree();
        });
}

/* this is called whenever the base layer form has changed */
function changedBaseLayer() {
    updateLinks();
}

/* =============================================================*/

function getWMSLayers() {
    var h = new Object;
    overlays.forEach(function(layer) {
        var ll = expand_layers[layer] || [];
        ll.forEach(function(elayer) {
            h[elayer] = true;
        });
    });
    var items = view_data.layers.filter(item => h[item] === true);
    return items.join(',');
}



function getQueryableLayers() {
    var layers = new Array;
    jQuery.ui.fancytree.getTree('#overlays').visit(function(node) {
        if (node.data.osmi_queryable && map.getView().getZoom() >= node.data.osmi_zmin && map.getView().getZoom() <= node.data.osmi_zmax) {
            layers.push(node.key);
        }
        return true;
    });
    return layers;
}

/* =============================================================*/

/* change map to show current base layer */
function updateBaseLayerInMap() {
    if (baselayer == 'None') {
    } else {
        baselayers.forEach(function(layer, name, baselayersDict) {
            if (name == baselayer) {
                map.addLayer(layer)
                layer.setVisibile(true);
            }
        });
    }
}

/* update everything in current view */
function updateView(tree) {
    if (tree == null) {
        tree = jQuery.ui.fancytree.getTree('#overlays');
    }
    updateOverlayShading(tree);
    updateLinks();
        
}

/* update overlay table shading to show currently visible layers depending on zoom level */
function updateOverlayShading(tree) {
    if (tree == null) {
        return;
    }
    var z = map.getView().getZoom();
    var count_visible = 0;
    tree.visit(function(node) {
        var zmin = node.data.osmi_zmin;
        var zmax = node.data.osmi_zmax;
        if (zmin <= z && z <= zmax) {
            node.removeClass('layer-disabled');
            count_visible++;
        } else {
            node.addClass('layer-disabled');
        }
        return true;
    });
}

/**
 * Update the permalink.
 */
function updateLinks() {
    if (!map) {
        return;
    }
    var center = olProj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
    var zoom = map.getView().getZoom();

    var url = window.location.protocol + '//' + window.location.host;
    url += window.location.pathname + '?view=' + current_view['id'] +
        '&lon=' + center[0].toFixed(5) + '&lat=' + center[1].toFixed(5) + '&zoom=' + zoom;
    // find current base layer
    var enabledBaseLayer = null;
    map.getLayers().forEach(function (l) {
        if (l.getVisible() && l.getProperties().hasOwnProperty('title')) {
            enabledBaseLayer = l.getProperties().title;
        }
    });
    var baselayer_tmp = null;
    for (const [name, layer] of Object.entries(baselayers)) {
        if (layer.getProperties().title == enabledBaseLayer) {
            baselayer_tmp = name;
        }
    }
    if (baselayer_tmp) {
        // remove whitespaces to preserve permalink
        url += '&baselayer=' + encodeURIComponent(baselayer_tmp);
    }
    if (opacity != default_opacity) {
        url += '&opacity=' + opacity.toFixed(2);
    }
    var o = overlays.join(',');
    if (o != overlays_all.join(',')) {
        url += '&overlays=' + encodeURIComponent(o);
    }
    if (mlon && mlat) {
        url += '&mlon=' + mlon + '&mlat=' + mlat;
    }
    history.replaceState('', document.title, url);
}

/**
 * Return true if editing is enabled on current zoom level.
 */
function editableZoomLevel() {
    return map.getView().getZoom() >= MIN_EDIT_ZOOM;
}


/**
 * Enable or disable the editor buttons depending on the current zoom level.
 */
function checkEditorButtons() {
    var disabled = !editableZoomLevel();
    var editorControlButtons = document.querySelectorAll('.olextra-editor-control button');
    for (var i = 0; i < editorControlButtons.length; ++i) {
        if (disabled) {
            editorControlButtons[i].setAttribute('disabled', 'disabled');
        } else {
            editorControlButtons[i].removeAttribute('disabled');
        }
    }
}

function triggerClickOfFirst(className) {
    var firstElem = document.getElementsByClassName(className)[0];
    if (firstElem && typeof firstElem[0].onclick == 'function') {
        firstElem.onclick.apply(firstElem);
    }
}

function key_pressed(event) {
    if (event.isComposing) {
        return;
    }
    var c = event.key;
    if (c == 'j') {
        triggerClickOfFirst('sel_link_to_josm');
    } else if (c == 'J') {
        openJOSMforMap();
    } else if (c == 'z') {
        triggerClickOfFirst('zoom_to_obj');
    } else if (c == 'i'){
        var el = document.getElementsByClassName('sel_link_to_id')[0];
        if (el) {
            window.open(el.href);
        }
    } else if (c == 'I'){
        openIDforMap();
    }
}

/* =============================================================*/

function showOrHideInfo(event, data) {
    // event is null if it is called by an opening control modal
    var hasInfo = false;
    if (data) {
        hasInfo = data.node.key.substring(0, 1) != '_';
    }
    var overlayinfos = document.getElementById('overlayinfos');
    var infos = document.getElementsByClassName('info');
    if (!infoVisible && hasInfo) {
        var layerInfo = document.getElementById('popupinfo_' + data.node.key);
        if (layerInfo) {
            layerInfo.classList.add('layer-info-visible');
            overlayinfos.classList.add('overlayinfos-visible');
        }
    } else {
        var overlayinfosChildren = document.getElementById('overlayinfos-content').children;
        for (var i = 0; i < overlayinfosChildren.length; ++i) {
            overlayinfosChildren[i].classList.remove('layer-info-visible');
        }
        overlayinfos.classList.remove('overlayinfos-visible');
    }
    infoVisible = !infoVisible;
    if (event != null) {
        showOrHideControlModal('', true);
    }
}

function setMarker(lon, lat) {
    map.removeOverlay(marker);
    marker = new Feature({
        type: 'icon',
        geometry: new Point([mlon, mlat]),
    });
    var markerStyle = new Style({
            image: new Icon({
            anchor: [0.5, 1],
            size: [21, 25],
            src: '/img/marker.png',
        }),
    });
    var vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [marker],
        }),
        style: markerStyle
    });
    map.addOverlay(vectorLayer);
}

function openJOSMforMap() {
    if (!editableZoomLevel()) {
        return;
    }
    fetch(createJOSMLink(
        olProj.transformExtent(
            map.getView().calculateExtent(map.getSize()),
            projmerc,
            proj4326
        )
    ));
}

function createJOSMLink(extent, objtype, objid) {
    var e = extent;
    var dx = e[2] - e[0];
    var dy = e[3] - e[1];
    e[0] -= Math.max(dx/10, 0.001);
    e[1] -= Math.max(dy/10, 0.001);
    e[2] += Math.max(dx/10, 0.001);
    e[3] += Math.max(dy/10, 0.001);
    var url = 'http://127.0.0.1:8111/load_and_zoom?left=' + e[0].toFixed(5) +
                                               '&bottom=' + e[1].toFixed(5) +
                                                '&right=' + e[2].toFixed(5) +
                                                  '&top=' + e[3].toFixed(5);
    if (objtype && objid) {
        url += '&select=' + longtype[objtype] + objid;
    }
    return url;
}

function getOsmTypeAndId(feature) {
    var objType = feature.obj_type ? feature.obj_type : null;
    var objId = feature.obj_id ? feature.obj_id : null;
    if (objType != null && objId != null) {
        return [objType, objId];
    }
    var nodeId = feature.node_id ? feature.node_id : null;
    if (nodeId != null) {
        return ['n', nodeId];
    }
    var wayId = feature.way_id ? feature.way_id : null;
    if (wayId != null) {
        return ['w', wayId];
    }
    var relId = feature.rel_id ? feature.rel_id : null;
    if (relId != null) {
        return ['r', relId];
    }
    return [null, null];
}

function openIDforMap() {
    if (!editableZoomLevel()) {
        return;
    }
    window.open(createIDLink());
}

function createIDLink(geometry, objtype, objid) {
    var extent = olProj.transformExtent(
            map.getView().calculateExtent(map.getSize()),
            projmerc,
            proj4326
        );
    var zoom = map.getView().getZoom();
    if (geometry || null) {
        extent = olProj.transformExtent(geometry.getExtent(), projmerc, proj4326);
        var currentView = map.getView();
        var virtualView = new View({zoom: currentView.getZoom(), center: currentView.getCenter()});
        virtualView.fit(geometry);
        zoom = Math.min(virtualView.getZoom(), 19);
    }
    var center = [extent[0] + 0.5 * (extent[2] - extent[0]), extent[1] + 0.5 * (extent[3] -  extent[1])];
    var url = 'https://www.openstreetmap.org/edit?editor=id';
    if (objtype && objid) {
        url += '&' + encodeURIComponent(longtype[objtype]) + '=' + objid;
    }
    url += '#map=' + zoom + '/' + center[1].toFixed(5) + '/' + center[0].toFixed(5);
    return url;
}

function createDataBrowserLink(objtype, objid) {
    var url = "https://www.openstreetmap.org/" + longtype[objtype] + '/' + objid;
    return url;
}
function createDeepHistoryLink(objtype, objid) {
    var url = "http://osm.mapki.com/history/" + longtype[objtype] + '.php?id=' + objid;
    return url;
}
